<template>
    <div class="ChangeRootPassword app-main-card">
        <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item ref="rootPass" label="修改密码" prop="rootPass">
                <a-input
                    v-model.trim="form.rootPass"
                    @blur="
                        () => {
                            $refs.rootPass.onFieldBlur()
                        }
                    "
                />
            </a-form-model-item>

            <a-form-model-item :wrapper-col="{span: 14, offset: 4}">
                <a-button type="primary" @click="onSubmit"> Submit </a-button>
                <a-button style="margin-left: 10px" @click="resetForm"> Reset </a-button>
            </a-form-model-item>
        </a-form-model>
    </div>
</template>

<script>
    import {mapActions} from 'vuex';
    export default {
        name: 'ChangeRootPassword',

        data() {
            return {
                labelCol: {span: 4},
                wrapperCol: {span: 14},
                other: '',
                form: {
                    rootPass: '',
                },
                rules: {
                    rootPass: [{required: true, message: 'Please input your Password!', trigger: 'change'}],
                },
            };
        },
        methods: {
            ...mapActions({vxChangeRoot: 'root/changeRoot'}),
            onSubmit() {
                this.$refs.ruleForm.validate(async valid => {
                    if (valid) {
                        await this.vxChangeRoot(valid)
                        this.$message.success('修改成功')
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm() {
                this.$refs.ruleForm.resetFields();
            },
        },
    };
</script>

<style lang="less" scoped>
</style>